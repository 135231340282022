$primary: #EE2E24;
*{
    box-sizing: border-box;
}
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/badge";

input, .select-dropdown, textarea{
    margin-block: 8px;
}
.alert{
    background: none;
}
.align-self-center{
    align-self: center;
}
.text-right{
    text-align: right;
}
body{
    padding-bottom: 100px;
}
.m-0{
    margin: 0 !important;
}
.mb-20{
    margin-bottom: 20px !important;
}
.mb-8{
    margin-bottom: 8px !important;
}
.mt-8{
    margin-top: 8px !important;
}
.mt-20{
    margin-top: 20px !important;
}
.mt-40{
    margin-top: 40px !important;
}
.mt-12{
    margin-top: 12px !important;
}
.mblock-4{
    margin-block: 4px !important;
}
.mblock-8{
    margin-block: 8px !important;
}
.mr-8{
    margin-right: 8px !important;
}
.mr-20{
    margin-right: 20px !important;
}
.pt-20{
    padding-top: 20px !important;
}
.p-8{
    padding: 8px !important;
}
.width-100{
    width: 100%;
}
.justify-content-between{
    justify-content: space-between;
}
.tyres-row{
    padding-block: 20px;
    &:last-of-type{
        padding-bottom: 0;
    }
}
.color-white{
    color: #fff;
}
.service-label{
    font-size: 16px;
    font-weight: bold;
}
.d-inline{
    display: inline;
}
.d-flex{
    display: flex;
}
.justify-content-center{
    justify-content: center;
}
.align-items-center{
    align-items: center;
}
table{
    td{
        text-align: center;
        vertical-align: middle;
    }
    td.action-2{
        width: 230px;
    }
    td.action-1{
        width: 115px;
    }
    td.action-3{
        width: 350px;
    }
    &.table-bordered{
        border: 1px solid #000;
    }
}
 a{
    text-decoration: none;
 }

 .card{
    &.bg-success{
        background: $success;
    }
    &.bg-danger{
        background: $danger;
    }
    &.bg-primary{
        background: $primary;
    }
    
}
.color{
    &-primary{
        color: $primary;
    }
}
.mouse-pointer{
    cursor: pointer;
}
.btn-disabled{
    pointer-events: none;
    cursor: not-allowed;
    background: #ccc;
    border-color: #ddd;
}

.nav{
    &-desktop{
        @media screen and (max-width: 991px){
            display: none;
         }
    }
    &-mobile{
        display: none;
        @media screen and (max-width: 991px){
            display: block;
        }
        background: #fff;
        top: 0;
        padding-left:25px;
        padding-top: 20px;
        ul{
            li{
                a{
                    font-size: 20px;
                    line-height: 26px;
                }
            }
        }
        
        
    }
}
.bm-burger-button{
    top: 10px !important;
    position: relative;
}
.bm-cross-button{
    top:40px !important;
    right: 30px !important;
}
.bm{
    &-overlay, &-burger-button{
        position: relative;
        display: none;
        @media screen and (max-width: 991px){
            display: block;
        }
    }
}
h3{
    margin-bottom: 12px;
}
.text-center{
    text-align: center;
}
.btn-primary{
    color:$white;
    &:hover, &:focus, &:active{
        color:$white;
    }
}
.btn-outline-primary{
    &:hover, &:focus, &:active{
        color:$white;
    }
}
.tyres-row{
    position: relative;
    
    &:after{
        content: "";
        position: absolute;
        bottom: 0;
        left: calc(50% - 2.5px);
        width: 5px;
        height: 50%;
        background: #ccc;
    }
    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: calc(50% - 2.5px);
        width: 5px;
        height: 50%;
        background: #ccc;
    }
    &:last-of-type{
        &:after{
            content: "REAR";
            position: absolute;
            bottom: 0%;
            font-size: 20px;
            font-weight: bold;
            left: calc(50% - 25px);
            width: 0;
            height: 0;
        }
    }
    &:first-of-type{
        &:before{
            content: "FRONT";
            position: absolute;
            top: 0%;
            font-size: 20px;
            font-weight: bold;
            left: calc(50% - 25px);
            width: 0;
            height: 0;
        }
    }
}
.tyres-row .col-3{
    &::after{
        content:'';
        border-bottom: 5px solid #ccc;
        position: absolute;
        top: calc(50% - 2.5px);
        width: calc(50% + 24px);
        left: calc(25% - 12px);
        z-index: -1;
    }
    &:last-of-type{
        &::before{
            content:'';
            background: #ccc;
            position: absolute;
            top: calc(50% - 10px);
            height: 20px;
            width: 20px;
            border-radius: 50%;
            right: calc(50% - 10px);
            z-index: -1;
        }
    }
}
// :after {
//     left:100%;
// }
// :before {
//     right:100%;
// }
.tyres-row .col-6:first-of-type:before,
.tyres-row .col-6:last-of-type:after {
    display:none;
}

@media screen and (max-width:767px) {
    .tyres-row{
        .col-6{
            &:first-of-type{
                order: 1;
            }
            &:last-of-type{
                order: 2;
            }
            &:nth-of-type(2){
                order: 3;
            }
            &:nth-of-type(3){
                order: 4;
            }
        }
    }    
}

.map-wrapper{
    width: 100%;
    height: 600px;
    // margin-block: 20px;
}
.current-vehicle-location{
    cursor: pointer;
    text-decoration: underline;
    font-style: italic;
    margin-top: 20px;
    color: $primary
}
.border-right-primary{
    border-right: 1px solid $primary;
}
.border-primary{
    border: 1px solid $primary;
}
.pt-20{
    padding-top: 20px;
}
.pb-20{
    padding-bottom: 20px;
}
.p-inline-20{
    padding-inline: 20px;
}
.cursor-pointer{
    cursor: pointer;
}
.background-grey{
    background: #f5f5f5;
}
.maintenance-service-card{
    &.text-white{
        color:white;
    }
}
.day-time-picker{
    div:first-child{
        background: $primary;
        color: #fff;
    }
}
.text-small{
    font-size: 14px;
}
.tyre-card{
    width: 40px;
    height: 100px;
    background: #455560;
}
.tyres-row{
    .col-3{
        &:nth-of-type(1), &:nth-of-type(3){
            .tyre-card{
                margin-left: auto;
            }
        }
    }
}
.action-row{
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 30px;
    background: #fff;
    width: 100%;
    text-align: center;
    .btn{
        min-width: 250px;
    }
}
@media screen and (max-width: 767px) {
    .mt-mobile-20{
        margin-top: 20px ;
    }
}
    