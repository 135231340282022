.navItem{
    text-decoration: none;
    color: inherit;
    display: block;
    margin-inline:10px;
}
.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
}