.logo{
    color: #323131;
    text-align: left;
    margin-bottom:0;
    margin-top: 0;
    @media screen and (max-width: 991px){
        margin-top: 20px;
    }
    .logoRed{
        color: #EE2E24;
    }
    .logoBy{
        color: #323131;
        display: inline;
        font-size: 14px;
    }
}
.logoImage{
    height: 30px;
}
.logoWrapper{
    display: block;
    max-width: 200px;
}
.logoTitle{
    margin: 0;
}